import React from 'react';
import { Image } from 'semantic-ui-react';
import Logo from '../../assets/logo-icon.svg';

const InstructionItem = props => {
  const { heading, details } = props;

  return (
    <div className="instruction-item">
      <div className="instruction-item__icon">
        <Image src={Logo} alt="logo" />
      </div>
      <span className="instruction-item__heading">{heading}</span>
      <span className="instruction-item__details">{details}</span>
    </div>
  );
};

export default InstructionItem;
