import { modalActionType } from '../actions/actionTypes';

const initialState = null;

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case modalActionType.OPEN: {
      const { modalType, modalProps } = action.payload;
      return { modalType, modalProps };
    }

    case modalActionType.CLOSE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default modalReducer;
