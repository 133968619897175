import React from 'react';
import { connect } from 'react-redux';
import EmployeeCard from './EmployeeCard';

const EmployeeCards = props => {
  const { employees } = props;

  return (
    <div className="employee-cards">
      {employees.map(employee => (
        <EmployeeCard key={employee.id} employee={employee} />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  employees: state.employees
});

export default connect(mapStateToProps)(EmployeeCards);
