import { firebase } from './firebaseConfig';
import uuidv4 from 'uuid/v4';

export const uploadImageAsync = (folder, file) => {
  return async (dispatch, getState) => {
    try {
      const storageRef = firebase.storage().ref(folder);
      const imageName = `${uuidv4()}.jpg`;
      const uploadRef = storageRef.child(imageName);
      await uploadRef.put(file);
      const downloadUrl = await uploadRef.getDownloadURL();
      return downloadUrl;
    } catch (error) {
      throw error;
    }
  };
};
