import { asyncActionType } from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: ''
};

const asyncReducer = (state = initialState, action) => {
  switch (action.type) {
    case asyncActionType.START: {
      return { ...state, loading: true, error: '' };
    }

    case asyncActionType.FINISH: {
      return initialState;
    }

    case asyncActionType.ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    default:
      return state;
  }
};

export default asyncReducer;
