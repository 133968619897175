import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
import { addServiceAsync } from '../../../store/actions/serviceActions';
import ServiceForm from './ServiceForm';

const AddService = props => {
  const { addServiceAsync, history } = props;
  const redirectUrl = '/admin/services'; // change to reference a constant later

  const onAdd = async service => {
    try {
      await addServiceAsync(service);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem adding service');
    }
  };

  return (
    <div className="add-service admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Add Service
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <ServiceForm onSubmit={onAdd} />
      </Segment>
    </div>
  );
};

const mapDispatchToProps = { addServiceAsync };

export default connect(
  null,
  mapDispatchToProps
)(AddService);
