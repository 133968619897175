import { modalActionType } from './actionTypes';

export const openModal = (modalType, modalProps) => {
  return {
    type: modalActionType.OPEN,
    payload: {
      modalType,
      modalProps
    }
  };
};

export const closeModal = () => {
  return {
    type: modalActionType.CLOSE
  };
};
