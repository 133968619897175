import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

const ServicesInfo = () => {
  return (
    <div className="services-info info-card">
      <div className="info-card__image">
        <Image src="/assets/pharmacist.jpg" alt="Pharmacist" />
      </div>

      <div className="info-card__main">
        <h3 className="info-card__headline">
          We’ve set out to provide a comprehensive list of our services. We hope
          you find it useful.
        </h3>
        <p className="info-card__description">
          For more detail, please use the services menu link or tap{' '}
          <Link className="info-card__link" to="/services">
            here
          </Link>{' '}
          to jump straight there.
        </p>
      </div>
    </div>
  );
};

export default ServicesInfo;
