import React from 'react';
import { Image } from 'semantic-ui-react';

const EmployeeCard = props => {
  const { employee } = props;

  return (
    <div className="employee-card">
      <div className="employee-card__image">
        <Image src={employee.imageUrl} alt="Profile" />
      </div>
      <div className="employee-card__name">{employee.name}</div>
      <div className="employee-card__role">{employee.role}</div>
      <p className="employee-card__bio">{employee.bio}</p>
    </div>
  );
};

export default EmployeeCard;
