import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EmployeeAdminRouter from './EmployeeAdminRouter';
import HoursAdminRouter from './HoursAdminRouter';
import ResourceAdminRouter from './ResourceAdminRouter';
import ServiceAdminRouter from './ServiceAdminRouter';

const AdminRouter = () => {
  return (
    <Switch>
      <Route path="/admin/employees" component={EmployeeAdminRouter} />
      <Route path="/admin/hours" component={HoursAdminRouter} />
      <Route path="/admin/resources" component={ResourceAdminRouter} />
      <Route path="/admin/services" component={ServiceAdminRouter} />
      <Redirect to="/admin/resources" />
    </Switch>
  );
};

export default AdminRouter;
