import { hoursApi } from '../../data/dataApi';
import { hoursActionType } from './actionTypes';
import { asyncError, asyncFinish, asyncStart } from './asyncActions';
import { addItem, deleteItem, setItems, updateItem } from './actionHelpers';
import moment from 'moment';

export const getBusinessHours = hours => {
  let items = [];
  let startDay = '';
  let endDay = '';
  let opens = '';
  let closes = '';

  const addItemToArray = () => {
    // Hours do not match, add item to array
    let opensAt = '';
    let closesAt = '';

    if (!!opens) {
      opensAt = moment(opens, ['HH:mm']).format('h:mma');
      closesAt = moment(closes, ['HH:mm']).format('h:mma');
    }

    const times = !!opens ? `${opensAt} - ${closesAt}` : '';
    if (endDay === '') {
      items.push({ name: startDay, times });
    } else {
      const days = `${startDay} - ${endDay}`;
      items.push({ name: days, times });
    }
  };

  if (hours.length !== 0) {
    for (let i = 0; i < hours.length; i++) {
      if (startDay === '') {
        // New array item or first day processed
        startDay = hours[i].name;
        opens = hours[i].opens;
        closes = hours[i].closes;
        continue;
      }

      if (opens === hours[i].opens && closes === hours[i].closes) {
        // Hours for current day match previous one, so adjust the end day of the pending array item
        endDay = hours[i].name;
        continue;
      }

      addItemToArray();

      startDay = hours[i].name;
      endDay = '';
      opens = hours[i].opens;
      closes = hours[i].closes;
    }

    // Add final item to array
    addItemToArray();
  }

  return items;
};

export const addHoursAsync = (data = { opens: '', closes: '' }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());

      const { name } = data;
      const opens = data.opens ? data.opens : '';
      const closes = data.closes ? data.closes : '';

      if (!name) {
        throw new Error('Invalid input');
      }

      const hours = { name, opens, closes };

      const id = await hoursApi.createAsync(hours);
      const newHours = { id, ...hours };
      dispatch(addItem(newHours, hoursActionType.ADD));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.message));
      throw new Error('Could not add hours');
    }
  };
};

export const deleteHoursAsync = id => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await hoursApi.removeAsync(id);
      dispatch(deleteItem(id, hoursActionType.DELETE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not delete hours');
    }
  };
};

export const setHoursAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      const hours = await hoursApi.getAllAsync();
      dispatch(setItems(hours, hoursActionType.SET));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw error;
    }
  };
};

export const updateHoursAsync = (id, hours) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await hoursApi.updateAsync(id, hours);
      dispatch(updateItem(id, hours, hoursActionType.UPDATE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not update hours');
    }
  };
};
