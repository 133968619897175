import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { openModal } from '../../store/actions/modalActions';

const ResourcesMenu = props => {
  const { resources, openModal } = props;

  const handleModal = resource => () => {
    openModal('ResourceModal', { resource });
  };

  return (
    <Dropdown floating text="Resources" className="resources-menu link item">
      <Dropdown.Menu>
        {resources &&
          resources.map(resource => (
            <Dropdown.Item key={resource.id} onClick={handleModal(resource)}>
              {resource.title}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = state => ({
  resources: state.resources
});

const mapDispatchToProps = { openModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesMenu);
