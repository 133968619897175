import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'semantic-ui-react';
import TextInput from '../controls/TextInput';

import {
  hasLengthGreaterThan,
  isRequired,
  combineValidators,
  composeValidators,
  createValidator
} from 'revalidate';

const isValidEmail = createValidator(
  message => value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  'Please check email address ... it appears to be in an invalid format.'
);

const RegisterForm = props => {
  const { handleSubmit, onRegister, invalid, submitting, pristine } = props;

  return (
    <Form
      size="large"
      className="registerForm"
      onSubmit={handleSubmit(onRegister)}
    >
      <Field
        name="displayName"
        type="text"
        component={TextInput}
        placeholder="Display name"
      />

      <Field
        name="email"
        type="email"
        component={TextInput}
        placeholder="Email address you'd like to use"
      />

      <Field
        name="password"
        type="password"
        component={TextInput}
        placeholder="Enter a password for our site"
      />

      <Button disabled={invalid || submitting || pristine} fluid color="green">
        Register
      </Button>
    </Form>
  );
};

const validate = combineValidators({
  displayName: isRequired({
    message: 'How would you like to be known to us?'
  }),
  email: composeValidators(
    isRequired({
      message: 'Please enter an email address'
    }),
    isValidEmail
  )(),
  password: composeValidators(
    isRequired({
      message: 'Please enter a password for our website'
    }),
    hasLengthGreaterThan(5)({
      message: 'Password needs to be at least 6 characters'
    })
  )()
});

export default reduxForm({ form: 'registerForm', validate })(RegisterForm);
