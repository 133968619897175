import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, List, Segment } from 'semantic-ui-react';
import ServiceListItem from './ServiceListItem';

const ServiceList = props => {
  const { services, history } = props;

  const handleAdd = () => {
    history.push('/admin/services/add');
  };

  return (
    <div className="service-list admin-list">
      <Button
        className="admin-button__add"
        onClick={handleAdd}
        content="Add"
        icon="add"
        size="tiny"
      />

      <Segment>
        <List className="service-list__items">
          {services.map(service => (
            <ServiceListItem
              key={service.id}
              service={service}
              history={history}
            />
          ))}
        </List>
      </Segment>
    </div>
  );
};

const mapStateToProps = state => ({
  services: state.services
});

export default withRouter(connect(mapStateToProps)(ServiceList));
