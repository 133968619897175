import React, { Component } from 'react';
import { Modal, Input, Button, Icon } from 'semantic-ui-react';
import mime from 'mime-types';
import ModalErrorMessage from './ModalErrorMessage';

const authorizedTypes = ['image/jpeg', 'image/png'];
const initialState = { error: '', file: '' };

export class FilePickerModal extends Component {
  state = initialState;

  isAuthorized = filename => authorizedTypes.includes(mime.lookup(filename));

  resetState = () => this.setState(initialState);

  onCancel = () => {
    this.resetState();
    this.props.closePicker();
  };

  onConfirm = () => {
    const { file } = this.state;

    if (file !== null) {
      const metadata = { contentType: mime.lookup(file.name) };
      this.resetState();
      this.props.handleSelection(file, metadata);
    }
  };

  validateFileType = event => {
    const file = event.target.files[0];
    const isValid = file && this.isAuthorized(file.name);

    if (isValid) {
      this.setState({ file, error: '' });
    } else {
      this.setState({ file: '', error: 'Please only pick jpg or png images' });
    }
  };

  render() {
    const { isOpen } = this.props;
    const { file, error } = this.state;

    return (
      <Modal basic size="small" open={isOpen}>
        <Modal.Content>
          <ModalErrorMessage error={error} />
          <Input onChange={this.validateFileType} fluid type="file" />
        </Modal.Content>
        <Modal.Actions>
          <Button.Group fluid>
            <Button
              disabled={!file}
              onClick={this.onConfirm}
              color="green"
              inverted
            >
              <Icon name="checkmark" /> Confirm
            </Button>
            <Button color="red" inverted onClick={this.onCancel}>
              <Icon name="remove" /> Cancel
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default FilePickerModal;
