import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import AddHours from '../components/hours/admin/AddHours';
import EditHours from '../components/hours/admin/EditHours';
import HoursAdmin from '../components/hours/admin/HoursAdmin';

const HoursAdminRouter = () => {
  return (
    <Switch>
      <AdminRoute exact path="/admin/hours/add" component={AddHours} />
      <AdminRoute exact path="/admin/hours/edit/:id" component={EditHours} />
      <AdminRoute exact path="/admin/hours" component={HoursAdmin} />
      <Redirect to="/admin/hours" />
    </Switch>
  );
};

export default HoursAdminRouter;
