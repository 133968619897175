import { authenticationApi } from '../../data/authenticationApi';
import { authActionType } from './actionTypes';

export const login = user => {
  const action = {
    type: authActionType.LOGIN,
    user
  };

  return action;
};

export const loginAsync = (credentials = {}) => {
  return async (dispatch, getState) => {
    try {
      return await authenticationApi.loginUserAsync(credentials);
    } catch (error) {
      throw error;
    }
  };
};

export const logout = () => {
  const action = {
    type: authActionType.LOGOUT
  };

  return action;
};

export const logoutAsync = () => {
  return async (dispatch, getState) => {
    try {
      return await authenticationApi.logoutUserAsync();
    } catch (error) {
      throw error;
    }
  };
};

export const registerAsync = (user = {}) => {
  return async (dispatch, getState) => {
    try {
      await authenticationApi.registerUserAsync(user);
    } catch (error) {
      throw error;
    }
  };
};
