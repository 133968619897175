import React from 'react';
import { Icon } from 'semantic-ui-react';

const ContactInfoItem = props => {
  const { iconName, detail } = props;

  return (
    <div className="contact-info-item">
      <Icon className="contact-info-item__icon" name={iconName} />
      <span className="contact-info-item__detail">{detail}</span>
    </div>
  );
};

export default ContactInfoItem;
