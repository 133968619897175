import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import asyncReducer from './asyncReducer';
import authReducer from './authReducer';
import employeeReducer from './employeeReducer';
import hoursReducer from './hoursReducer';
import modalReducer from './modalReducer';
import resourceReducer from './resourceReducer';
import serviceReducer from './serviceReducer';

const rootReducer = combineReducers({
  async: asyncReducer,
  auth: authReducer,
  employees: employeeReducer,
  form: formReducer,
  hours: hoursReducer,
  modals: modalReducer,
  resources: resourceReducer,
  services: serviceReducer
});

export default rootReducer;
