import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { Form, Button, Image, Grid, Icon } from 'semantic-ui-react';
import { uploadImageAsync } from '../../../data/mediaApi';
import ImagePicker from '../../helpers/ImagePicker';

import {
  hasLengthGreaterThan,
  isRequired,
  combineValidators,
  composeValidators
} from 'revalidate';

import TextInput from '../../controls/TextInput';
import TextArea from '../../controls/TextArea';

export class EmployeeForm extends Component {
  state = {
    employeeImageUrl: '',
    pickerOpen: false
  };

  componentDidMount() {
    const { employee } = this.props;

    if (employee && employee.imageUrl) {
      this.setState({ employeeImageUrl: employee.imageUrl });
    }
  }

  handleImage = async image => {
    try {
      const { uploadImageAsync } = this.props;
      const downloadUrl = await uploadImageAsync('cms/employees', image);
      this.props.dispatch(change('employeeForm', 'imageUrl', downloadUrl));
      this.setState({ employeeImageUrl: downloadUrl });
    } catch (error) {
      alert('Problem uploading image');
    }
  };

  closePicker = () => {
    this.setState({ pickerOpen: false });
  };

  openPicker = () => {
    this.setState({ pickerOpen: true });
  };

  render() {
    const {
      handleSubmit,
      onSubmit,
      invalid,
      submitting,
      pristine,
      onRemove
    } = this.props;

    const { pickerOpen, employeeImageUrl } = this.state;

    return (
      <Fragment>
        <Form
          size="large"
          className="employeeForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={12} tablet={8} computer={6}>
                {employeeImageUrl && (
                  <Image src={employeeImageUrl} alt="Employee" />
                )}
              </Grid.Column>
              <Grid.Column
                mobile={4}
                tablet={8}
                computer={10}
                textAlign="right"
              >
                <Icon
                  name="image outline"
                  size="big"
                  onClick={this.openPicker}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field
                label="name"
                name="name"
                type="text"
                component={TextInput}
                placeholder="Employee name goes here ..."
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field
                label="role"
                name="role"
                type="text"
                component={TextInput}
                placeholder="Employee role goes here ..."
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field
                name="bio"
                type="text"
                component={TextArea}
                placeholder="Employee bio goes here ..."
                rows="5"
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Button.Group>
                <Button
                  positive
                  disabled={
                    invalid || submitting || pristine || !employeeImageUrl
                  }
                >
                  Save
                </Button>

                {onRemove && (
                  <Button negative onClick={onRemove} type="button">
                    Remove
                  </Button>
                )}
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Form>
        <ImagePicker
          aspectRatio={1 / 1}
          closePicker={this.closePicker}
          handleImage={this.handleImage}
          pickerOpen={pickerOpen}
        />
      </Fragment>
    );
  }
}

// Change heading to headline later
const validate = combineValidators({
  name: isRequired({ message: "Please enter the employee's name" }),
  role: isRequired({ message: "Please enter the employee's role" }),
  bio: composeValidators(
    isRequired({ message: 'Please enter a short bio for the employee' }),
    hasLengthGreaterThan(19)({
      message: 'Bio needs to be at least 20 characters'
    })
  )()
});

const mapStateToProps = (state, ownProps) => {
  return { initialValues: ownProps.employee || {} };
};

const mapDispatchToProps = { uploadImageAsync };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'employeeForm',
    enableReinitialize: true,
    validate
  })(EmployeeForm)
);
