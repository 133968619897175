import React, { Component } from 'react';
import { Image, Segment, Header } from 'semantic-ui-react';

export class ServiceSmallScreen extends Component {
  render() {
    const { service } = this.props;

    return (
      <Segment.Group className="service">
        <Segment className="service__header">
          <Header as="h1" className="service__title">
            {service.title}
          </Header>
        </Segment>

        <Segment className="service__image">
          <Image src={service.imageUrl} alt="Service" />
        </Segment>

        <Segment className="service__main">
          {service.headline && (
            <h3 className="service__headline">{service.headline}</h3>
          )}
          <p className="service__description">{service.description}</p>
        </Segment>
      </Segment.Group>
    );
  }
}

export default ServiceSmallScreen;
