import React, { Component, Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import ServicesSmallScreen from './servicesSmallScreen/ServicesSmallScreen';
import ServicesWideScreen from './servicesWideScreen/ServicesWideScreen';

export class Services extends Component {
  render() {
    return (
      <Fragment>
        <Responsive maxWidth={767}>
          <ServicesSmallScreen />
        </Responsive>
        <Responsive minWidth={768}>
          <ServicesWideScreen />
        </Responsive>
      </Fragment>
    );
  }
}

export default Services;
