export const addItem = (state, item) => {
  return [...state, item];
};

export const removeItem = (state, id) => {
  return [...state.filter(item => item.id !== id)];
};

export const updateItem = (state, id, item) => {
  return state.map(stateItem => {
    return stateItem.id === id ? { ...stateItem, ...item } : stateItem;
  });
};
