import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Container } from 'semantic-ui-react';
import AdminRouter from '../../routers/AdminRouter';

export class AdminSmallScreen extends Component {
  state = {};

  handleItemClick = (e, { name }) => {
    const activeItem = name;
    this.setState({ activeItem });
    this.props.history.push(`/admin/${name}`);
  };

  render() {
    const { pathname } = this.props.location;

    return (
      <Container className="admin-smallscreen">
        <Menu borderless className="admin-smallscreen__menu">
          <Menu.Item
            className="admin-smallscreen__menu__item"
            name="resources"
            active={pathname === '/admin/resources'}
            onClick={this.handleItemClick}
          >
            <Icon name="external" />
          </Menu.Item>
          <Menu.Item
            className="admin-smallscreen__menu__item"
            name="services"
            active={pathname === '/admin/services'}
            onClick={this.handleItemClick}
          >
            <Icon name="medkit" />
          </Menu.Item>
          <Menu.Item
            className="admin-smallscreen__menu__item"
            name="employees"
            active={pathname === '/admin/employees'}
            onClick={this.handleItemClick}
          >
            <Icon name="users" />
          </Menu.Item>
          <Menu.Item
            className="admin-smallscreen__menu__item"
            name="hours"
            active={pathname === '/admin/hours'}
            onClick={this.handleItemClick}
          >
            <Icon name="clock outline" />
          </Menu.Item>
          {/* <Menu.Item
            className="admin-smallscreen__menu__item"
            name="delivery"
            active={pathname === '/admin/delivery'}
            onClick={this.handleItemClick}
          >
            <Icon name="shipping fast" />
          </Menu.Item>
          <Menu.Item
            className="admin-smallscreen__menu__item"
            name="messages"
            active={pathname === '/admin/messages'}
            onClick={this.handleItemClick}
          >
            <Icon name="comment outline" />
          </Menu.Item> */}
        </Menu>

        <div className="admin-smallscreen__main">
          <AdminRouter />
        </div>
      </Container>
    );
  }
}

export default withRouter(AdminSmallScreen);
