import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import AddService from '../components/services/admin/AddService';
import EditService from '../components/services/admin/EditService';
import ServiceAdmin from '../components/services/admin/ServiceAdmin';

const ServiceAdminRouter = () => {
  return (
    <Switch>
      <AdminRoute exact path="/admin/services/add" component={AddService} />
      <AdminRoute
        exact
        path="/admin/services/edit/:id"
        component={EditService}
      />
      <AdminRoute exact path="/admin/services" component={ServiceAdmin} />
      <Redirect to="/admin/services" />
    </Switch>
  );
};

export default ServiceAdminRouter;
