import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ModalManager from './helpers/ModalManager';
import AppRouter from '../routers/AppRouter';
import Header from './header/Header';
import SideDrawer from './sideDrawer/SideDrawer';

export class App extends Component {
  state = {
    sideDrawerOpen: false
  };

  openSideDrawer = () => {
    this.setState({ sideDrawerOpen: true });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    const { sideDrawerOpen } = this.state;
    const { history } = this.props;

    return (
      <div className="app">
        <ModalManager />
        <Header openSideDrawer={this.openSideDrawer} history={history} />
        <SideDrawer
          history={history}
          handleClose={this.closeSideDrawer}
          isOpen={sideDrawerOpen}
        />
        {/* <Fragment> */}
        <AppRouter />
        {/* </Fragment> */}
      </div>
    );
  }
}

export default withRouter(App);
