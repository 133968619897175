import React from 'react';
import { Icon } from 'semantic-ui-react';
import { brand } from '../../data/constants';
import Hours from '../hours/Hours';

const HomePageFooter = () => {
  return (
    <div className="home-page-footer">
      <div className="home-page-footer__hours">
        <Icon className="home-page-footer__hours__icon" name="clock outline" />
        <Hours />
      </div>
      <div className="copyright-abn-container">
        <div className="home-page-footer__copyright">
          <Icon
            className="home-page-footer__copyright__icon"
            name="copyright outline"
          />
          <div className="home-page-footer__copyright__detail">
            {brand.COPYRIGHT}
          </div>
        </div>

        <div className="home-page-footer__abn">
          <div className="home-page-footer__abn__heading">ABN</div>
          <div className="home-page-footer__abn__detail">{brand.ABN}</div>
        </div>
      </div>
    </div>
  );
};

export default HomePageFooter;
