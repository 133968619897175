import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import HoursList from './HoursList';

const HoursAdmin = () => {
  return (
    <div className="hours-admin admin-section">
      <Segment className="admin-section__header" inverted color="green" padded>
        <Header as="h2" className="admin-section__title">
          Hours Admin
        </Header>
      </Segment>
      <HoursList />
    </div>
  );
};

export default HoursAdmin;
