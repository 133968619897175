import React from 'react';
import { Message, Divider } from 'semantic-ui-react';

const ModalErrorMessage = props => {
  const { error } = props;

  if (!error) {
    return null;
  }

  return (
    <React.Fragment>
      <Message negative compact>
        {error}
      </Message>
      <Divider color="red" />
    </React.Fragment>
  );
};

export default ModalErrorMessage;
