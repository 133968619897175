import React from 'react';
import { connect } from 'react-redux';
import HoursItem from './HoursItem';
import { getBusinessHours } from '../../store/actions/hoursActions';

const Hours = props => {
  const { hours } = props;

  return (
    <div className="hours">
      {hours && (
        <div className="hours__items">
          {hours.map((hoursItem, index) => (
            <HoursItem key={index} hoursItem={hoursItem} />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  hours: getBusinessHours(state.hours)
});

export default connect(mapStateToProps)(Hours);
