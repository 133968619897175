import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../controls/TextInput';

import {
  isRequired,
  combineValidators,
  composeValidators,
  hasLengthBetween
} from 'revalidate';

export class ResourceForm extends Component {
  render() {
    const {
      handleSubmit,
      onSubmit,
      invalid,
      submitting,
      pristine,
      onRemove
    } = this.props;

    return (
      <Form
        size="large"
        className="resourceForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field
              label="title"
              name="title"
              type="text"
              component={TextInput}
              placeholder="Resource title goes here ..."
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field
              label="resource url"
              name="url"
              type="text"
              component={TextInput}
              placeholder="Resource Url goes here ..."
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Button.Group>
              <Button positive disabled={invalid || submitting || pristine}>
                Save
              </Button>

              {onRemove && (
                <Button negative onClick={onRemove} type="button">
                  Remove
                </Button>
              )}
            </Button.Group>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

// Change heading to headline later
const validate = combineValidators({
  title: composeValidators(
    isRequired({ message: 'Please enter a title' }),
    hasLengthBetween(10, 50)({
      message: 'Title should be between 10 and 50 characters'
    })
  )(),
  url: isRequired({ message: 'Please enter the url for the external website' })
});

const mapStateToProps = (state, ownProps) => {
  return { initialValues: ownProps.resource || {} };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'resourceForm',
    enableReinitialize: true,
    validate
  })(ResourceForm)
);
