import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { Form, Button, Image, Grid, Icon } from 'semantic-ui-react';
import { uploadImageAsync } from '../../../data/mediaApi';
import ImagePicker from '../../helpers/ImagePicker';

import {
  hasLengthGreaterThan,
  isRequired,
  combineValidators,
  composeValidators,
  hasLengthBetween,
  hasLengthLessThan
} from 'revalidate';

import TextInput from '../../controls/TextInput';
import TextArea from '../../controls/TextArea';

export class ServiceForm extends Component {
  state = {
    serviceImageUrl: '',
    pickerOpen: false
  };

  componentDidMount() {
    const { service } = this.props;

    if (service && service.imageUrl) {
      this.setState({ serviceImageUrl: service.imageUrl });
    }
  }

  handleImage = async image => {
    try {
      const { uploadImageAsync } = this.props;
      const downloadUrl = await uploadImageAsync('cms/services', image);
      this.props.dispatch(change('serviceForm', 'imageUrl', downloadUrl));
      this.setState({ serviceImageUrl: downloadUrl });
    } catch (error) {
      alert('Problem uploading image');
    }
  };

  closePicker = () => {
    this.setState({ pickerOpen: false });
  };

  openPicker = () => {
    this.setState({ pickerOpen: true });
  };

  render() {
    const {
      handleSubmit,
      onSubmit,
      invalid,
      submitting,
      pristine,
      onRemove
    } = this.props;

    const { pickerOpen, serviceImageUrl } = this.state;

    return (
      <Fragment>
        <Form
          size="large"
          className="serviceForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={12} tablet={8} computer={6}>
                {serviceImageUrl && (
                  <Image src={serviceImageUrl} alt="Service" />
                )}
              </Grid.Column>
              <Grid.Column
                mobile={4}
                tablet={8}
                computer={10}
                textAlign="right"
              >
                <Icon
                  name="image outline"
                  size="big"
                  onClick={this.openPicker}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field
                label="title"
                name="title"
                type="text"
                component={TextInput}
                placeholder="Service title goes here ..."
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field
                name="headline" // Change to headline later
                type="text"
                component={TextArea}
                placeholder="Service description 'headline' goes here ..."
                rows="2"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field
                name="description"
                type="text"
                component={TextArea}
                placeholder="Service description goes here ..."
                rows="5"
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Button.Group>
                <Button
                  positive
                  disabled={
                    invalid || submitting || pristine || !serviceImageUrl
                  }
                >
                  Save
                </Button>

                {onRemove && (
                  <Button negative onClick={onRemove} type="button">
                    Remove
                  </Button>
                )}
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Form>
        <ImagePicker
          aspectRatio={16 / 9}
          closePicker={this.closePicker}
          handleImage={this.handleImage}
          pickerOpen={pickerOpen}
        />
      </Fragment>
    );
  }
}

// Change heading to headline later
const validate = combineValidators({
  title: composeValidators(
    isRequired({ message: 'Please enter a title' }),
    hasLengthBetween(10, 40)({
      message: 'Title should be between 10 and 40 characters'
    })
  )(),
  headline: hasLengthLessThan(101)({
    message: "'Headline' should be no more than 100 characters"
  }),
  description: composeValidators(
    isRequired({ message: 'Please enter a description' }),
    hasLengthGreaterThan(19)({
      message: 'Description needs to be at least 20 characters'
    })
  )()
});

const mapStateToProps = (state, ownProps) => {
  return { initialValues: ownProps.service || {} };
};

const mapDispatchToProps = { uploadImageAsync };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'serviceForm',
    enableReinitialize: true,
    validate
  })(ServiceForm)
);
