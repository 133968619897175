import React from 'react';
import { connect } from 'react-redux';
import HelpModal from '../helpers/HelpModal';
import LoginModal from '../authentication/LoginModal';
import RegisterModal from '../authentication/RegisterModal';
import ResourceModal from '../resources/ResourceModal';

const modalLookup = {
  HelpModal,
  LoginModal,
  RegisterModal,
  ResourceModal
};

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

const mapStateToProps = state => ({
  currentModal: state.modals
});

export default connect(mapStateToProps)(ModalManager);
