import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import { isRequired, combineValidators, hasLengthBetween } from 'revalidate';
import TextInput from '../../controls/TextInput';

export class HoursForm extends Component {
  render() {
    const {
      handleSubmit,
      onSubmit,
      invalid,
      submitting,
      pristine,
      onRemove
    } = this.props;

    return (
      <Form
        size="large"
        className="hoursForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field
              label="Name"
              name="name"
              type="text"
              component={TextInput}
              placeholder="Day of week goes here ..."
            />
          </Grid.Column>

          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              label="Opening Time"
              name="opens"
              type="text"
              component={TextInput}
              placeholder="Opening Time goes here ..."
            />
          </Grid.Column>

          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              label="Closing Time"
              name="closes"
              type="text"
              component={TextInput}
              placeholder="Closing time goes here ..."
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Button.Group>
              <Button positive disabled={invalid || submitting || pristine}>
                Save
              </Button>

              {onRemove && (
                <Button negative onClick={onRemove} type="button">
                  Remove
                </Button>
              )}
            </Button.Group>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

const validate = combineValidators({
  name: isRequired({ message: "Please enter the day's name" }),
  opens: hasLengthBetween(0, 5)({
    message: 'Opening time should be hh:mm format or blank'
  }),
  closes: hasLengthBetween(0, 5)({
    message: 'Closing time should be hh:mm format or blank'
  })
});

const mapStateToProps = (state, ownProps) => {
  return { initialValues: ownProps.hours || {} };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'hoursForm',
    enableReinitialize: true,
    validate
  })(HoursForm)
);
