import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
import { addResourceAsync } from '../../../store/actions/resourceActions';
import ResourceForm from './ResourceForm';

const AddResource = props => {
  const { addResourceAsync, history } = props;
  const redirectUrl = '/admin/resources'; // change to reference a constant later

  const onAdd = async resource => {
    try {
      await addResourceAsync(resource);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem adding resource');
    }
  };

  return (
    <div className="add-resource admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Add Resource
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <ResourceForm onSubmit={onAdd} />
      </Segment>
    </div>
  );
};

const mapDispatchToProps = { addResourceAsync };

export default connect(
  null,
  mapDispatchToProps
)(AddResource);
