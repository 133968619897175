import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import AddResource from '../components/resources/admin/AddResource';
import EditResource from '../components/resources/admin/EditResource';
import ResourceAdmin from '../components/resources/admin/ResourceAdmin';

const ResourceAdminRouter = () => {
  return (
    <Switch>
      <AdminRoute exact path="/admin/resources/add" component={AddResource} />
      <AdminRoute
        exact
        path="/admin/resources/edit/:id"
        component={EditResource}
      />
      <AdminRoute exact path="/admin/resources" component={ResourceAdmin} />
      <Redirect to="/admin/resources" />
    </Switch>
  );
};

export default ResourceAdminRouter;
