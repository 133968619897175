import React, { Component } from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import GoogleMapReact from 'google-map-react';

const Marker = () => (
  <Icon name="map marker alternate" size="big" color="red" />
);

export class LocationMap extends Component {
  static defaultProps = {
    center: { lat: -33.767689, lng: 151.187197 },
    zoom: 18
  };

  render() {
    return (
      <Segment attached="bottom" style={{ padding: 0 }}>
        <div style={{ height: '300px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyA-Bc4MSgXX80YvCQzjAEk8NiFW1MwPPgI'
            }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <Marker lat="-33.767518" lng="151.187495" />
          </GoogleMapReact>
        </div>
      </Segment>
    );
  }
}

export default LocationMap;
