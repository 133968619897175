import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { openModal } from '../../store/actions/modalActions';
import AccountMenu from './AccountMenu';
import ResourcesMenu from './ResourcesMenu';

const HeaderMenu = props => {
  const { openModal } = props;

  const handleModal = () => {
    openModal('HelpModal');
  };

  return (
    <Menu className="header-menu" secondary>
      <Menu.Item onClick={handleModal}>Help</Menu.Item>
      <Menu.Item as={Link} to="/about">
        About Us
      </Menu.Item>
      <Menu.Item as={Link} to="/services">
        Services
      </Menu.Item>
      <Menu.Menu position="right">
        <ResourcesMenu />
      </Menu.Menu>

      <Menu.Menu position="right">
        <AccountMenu />
      </Menu.Menu>
    </Menu>
  );
};

const mapDispatchToProps = { openModal };

export default connect(
  null,
  mapDispatchToProps
)(HeaderMenu);
