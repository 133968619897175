import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
// prettier-ignore
import { deleteServiceAsync,updateServiceAsync } from '../../../store/actions/serviceActions';
import ServiceForm from './ServiceForm';

const EditService = props => {
  const { deleteServiceAsync, history, service, updateServiceAsync } = props;
  const redirectUrl = '/admin/services'; // change to reference a constant later

  const onUpdate = async service => {
    try {
      await updateServiceAsync(service.id, service);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem updating service');
    }
  };

  const onRemove = async () => {
    try {
      await deleteServiceAsync(service.id);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem removing service');
    }
  };

  return (
    <div className="edit-service admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Edit Service
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <ServiceForm
          onSubmit={onUpdate}
          onRemove={onRemove}
          service={service}
        />
      </Segment>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  service: state.services.find(service => service.id === props.match.params.id)
});

const mapDispatchToProps = {
  deleteServiceAsync,
  updateServiceAsync
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditService);
