import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import ResourceCards from './ResourceCards';

const Resources = () => {
  return (
    <Grid padded className="resources">
      <Grid.Column width={16} className="resources__intro">
        <h3 className="resources__intro__primary">
          We’ve included some links to external websites we think you may find
          useful.
        </h3>

        <h4 className="resources__intro__secondary">
          Tap
          <Icon className="resources__intro__icon" name="external" />
          and you will be redirected to their website. When you're ready to
          return to ours, use the back button on your browser as normal.
        </h4>
      </Grid.Column>

      <Grid.Column width={16}>
        <ResourceCards />
      </Grid.Column>
    </Grid>
  );
};

export default Resources;
