import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { logoutAsync } from '../../store/actions/authActions';
import { openModal } from '../../store/actions/modalActions';

const AccountMenu = props => {
  const { auth, logoutAsync, openModal } = props;
  const isAuthenticated = !!auth.user;
  const isAdmin = isAuthenticated && auth.user.isAdmin;

  const handleModal = item => () => {
    openModal(item);
  };

  const handleSignout = () => {
    logoutAsync();
  };

  return (
    <Dropdown text="Account" className="account-menu link item">
      <Dropdown.Menu>
        {isAdmin && (
          <React.Fragment>
            <Dropdown.Item
              as={NavLink}
              to="/admin"
              icon="settings"
              text="Manage content"
            />
            <Dropdown.Divider />
          </React.Fragment>
        )}
        {isAuthenticated && (
          <Dropdown.Item
            onClick={handleSignout}
            icon="power off"
            text="Sign-out"
          />
        )}
        {!isAuthenticated && (
          <Dropdown.Item
            onClick={handleModal('LoginModal')}
            icon="user"
            text="Sign-in/Register"
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = { openModal, logoutAsync };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountMenu);
