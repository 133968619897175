import { serviceActionType } from '../actions/actionTypes';
import { addItem, removeItem, updateItem } from './reducerHelpers';

const initialState = [];

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case serviceActionType.ADD: {
      return addItem(state, action.item);
    }

    case serviceActionType.DELETE: {
      return removeItem(state, action.id);
    }

    case serviceActionType.UPDATE: {
      return updateItem(state, action.id, action.item);
    }

    case serviceActionType.SET: {
      return action.items;
    }

    default:
      return state;
  }
};

export default serviceReducer;
