import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Header, Icon } from 'semantic-ui-react';
import { registerAsync } from '../../store/actions/authActions';
import { closeModal, openModal } from '../../store/actions/modalActions';
import RegisterForm from './RegisterForm';

export class RegisterPage extends Component {
  state = {
    error: ''
  };

  handleRegistration = async user => {
    try {
      this.setState({ error: '' });
      await this.props.registerAsync(user);
      this.props.openModal('LoginModal', { user });
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  render() {
    const { error } = this.state;

    return (
      <div className="register-page">
        {error && (
          <React.Fragment>
            <Header as="h4">
              <Icon name="info" color="red" />
              <Header.Content>{error}</Header.Content>
            </Header>
            <Divider />
          </React.Fragment>
        )}
        <RegisterForm onRegister={this.handleRegistration} />
      </div>
    );
  }
}

const mapDispatchToProps = { closeModal, openModal, registerAsync };

export default connect(
  null,
  mapDispatchToProps
)(RegisterPage);
