import React from 'react';
import { List } from 'semantic-ui-react';

const ServiceListItem = props => {
  const { service, history } = props;

  const handleEdit = id => () => {
    history.push(`/admin/services/edit/${id}`);
  };

  return (
    <List.Item
      className="service-list-item admin-list-item"
      onClick={handleEdit(service.id)}
      key={service.id}
    >
      {service.title}
    </List.Item>
  );
};

export default ServiceListItem;
