import React from 'react';
import InstructionItem from '../instruction/InstructionItem';

const Help = () => {
  return (
    <div className="help">
      <InstructionItem
        heading="About Us"
        details="View our business hours, location and short biographies of our team members"
      />

      <InstructionItem
        heading="Services"
        details="View a detailed list of the services we offer"
      />

      <InstructionItem
        heading="Resources"
        details="We've added some links to external websites we think you may find useful"
      />

      <InstructionItem
        heading="Account"
        details="Our web chat system and online home delivery ordering services are coming soon. Register here and you will be notified when they are operational."
      />

      <InstructionItem
        heading="Homepage"
        details="Tap our main logo in the top left corner of any screen to return to our homepage"
      />
    </div>
  );
};

export default Help;
