import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, List, Segment } from 'semantic-ui-react';
import EmployeeListItem from './EmployeeListItem';

const EmployeeList = props => {
  const { employees, history } = props;

  const handleAdd = () => {
    history.push('/admin/employees/add');
  };

  return (
    <div className="employee-list admin-list">
      <Button
        className="admin-button__add"
        onClick={handleAdd}
        content="Add"
        icon="add"
        size="tiny"
      />

      <Segment>
        <List className="employee-list__items">
          {employees.map(employee => (
            <EmployeeListItem
              key={employee.id}
              employee={employee}
              history={history}
            />
          ))}
        </List>
      </Segment>
    </div>
  );
};

const mapStateToProps = state => ({
  employees: state.employees
});

export default withRouter(connect(mapStateToProps)(EmployeeList));
