import React, { Fragment } from 'react';

const HoursItem = props => {
  const { hoursItem } = props;
  const isOpen = !!hoursItem.times;

  return (
    <div className="hours-item">
      {isOpen && (
        <Fragment>
          <div className="hours-item__name">{hoursItem.name}</div>
          <div className="hours-item__times">{hoursItem.times}</div>
        </Fragment>
      )}

      {!isOpen && (
        <Fragment>
          <div className="hours-item__name">{hoursItem.name}</div>
          <div className="hours-item__times hours-item__times--closed">
            Closed
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default HoursItem;
