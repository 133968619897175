import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'semantic-ui-react';
import TextInput from '../controls/TextInput';

import {
  isRequired,
  combineValidators,
  composeValidators,
  createValidator
} from 'revalidate';

const isValidEmail = createValidator(
  message => value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  'Please check email address ... it appears to be in an invalid format.'
);

const LoginForm = props => {
  const { handleSubmit, onLogin, invalid, submitting, pristine } = props;

  return (
    <Form size="large" className="loginForm" onSubmit={handleSubmit(onLogin)}>
      <Field
        name="email"
        type="email"
        component={TextInput}
        placeholder="Email address you'd like to use"
      />

      <Field
        name="password"
        type="password"
        component={TextInput}
        placeholder="Enter a password for our site"
      />

      <Button disabled={invalid || submitting || pristine} fluid color="green">
        Sign-in
      </Button>
    </Form>
  );
};

const validate = combineValidators({
  email: composeValidators(
    isRequired({
      message: 'Please enter your registered email address'
    }),
    isValidEmail
  )(),
  password: isRequired({
    message: 'Please enter your password for our website'
  })
});

export default reduxForm({ form: 'loginForm', validate })(LoginForm);
