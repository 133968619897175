import React, { Component } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export class ImageCropperModal extends Component {
  onCrop = () => {
    if (typeof this.refs.cropper.getCroppedCanvas() === 'undefined') return;

    this.refs.cropper.getCroppedCanvas().toBlob(blob => {
      this.props.handleCrop(blob);
    }, 'image/jpeg');
  };

  render() {
    const { isOpen, handleClose, imageUrl, aspectRatio } = this.props;

    return (
      <Modal basic size="small" open={isOpen} onClose={handleClose}>
        <Modal.Content>
          <Cropper
            className="photo-cropper"
            style={{
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '300px'
            }}
            ref="cropper"
            src={imageUrl}
            aspectRatio={aspectRatio}
            viewMode={2}
            dragMode="move"
            guides={false}
            scalable={true}
            cropBoxMovable={true}
            cropBoxResizable={true}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button.Group fluid>
            <Button onClick={this.onCrop} color="green" inverted>
              <Icon name="checkmark" /> Crop
            </Button>
            <Button color="red" inverted onClick={handleClose}>
              <Icon name="remove" /> Cancel
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ImageCropperModal;
