import React, { Component, Fragment } from 'react';
import LocationMap from './LocationMap';
import { Button, Icon, Responsive } from 'semantic-ui-react';

export class Location extends Component {
  state = {
    showMap: false
  };

  toggleMap = () => {
    this.setState({ showMap: !this.state.showMap });
  };

  render() {
    const { showMap } = this.state;
    const buttonText = showMap ? 'Hide map' : 'Show map';

    return (
      <div className="location">
        <div className="location-address">
          <span className="location-address__item">5 Hughes Place</span>
          <span className="location-address__item">East Lindfield</span>
          <span className="location-address__item">Sydney</span>
          <span className="location-address__item">NSW 2070</span>
        </div>

        <div className="location-map">
          <Responsive maxWidth={767} as={Fragment}>
            <Button
              className="location-map__button"
              onClick={this.toggleMap}
              size="tiny"
            >
              <Icon name="map marker alternate" /> {buttonText}
            </Button>

            {showMap && <LocationMap />}
          </Responsive>

          <Responsive minWidth={768}>
            <LocationMap />
          </Responsive>
        </div>
      </div>
    );
  }
}

export default Location;
