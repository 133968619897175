import React from 'react';
import { List } from 'semantic-ui-react';

const EmployeeListItem = props => {
  const { employee, history } = props;

  const handleEdit = id => () => {
    history.push(`/admin/employees/edit/${id}`);
  };

  return (
    <List.Item
      className="employee-list-item admin-list-item"
      onClick={handleEdit(employee.id)}
      key={employee.id}
    >
      {employee.name}
    </List.Item>
  );
};

export default EmployeeListItem;
