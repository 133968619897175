import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import ServiceList from './ServiceList';

const ServiceAdmin = () => {
  return (
    <div className="service-admin admin-section">
      <Segment className="admin-section__header" inverted color="green" padded>
        <Header as="h2" className="admin-section__title">
          Service Admin
        </Header>
      </Segment>

      <ServiceList />
    </div>
  );
};

export default ServiceAdmin;
