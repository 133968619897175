import { asyncActionType } from './actionTypes';

export const asyncStart = () => {
  const action = {
    type: asyncActionType.START
  };

  return action;
};

export const asyncFinish = () => {
  const action = {
    type: asyncActionType.FINISH
  };

  return action;
};

export const asyncError = error => {
  const action = {
    type: asyncActionType.ERROR,
    error
  };

  return action;
};
