import React, { Component } from 'react';
import { connect } from 'react-redux';
import AliceCarousel from 'react-alice-carousel';
import EmployeeCard from '../employees/EmployeeCard';

export class EmployeeCardCarousel extends Component {
  responsive = {
    0: { items: 1 },
    600: { items: 2 },
    767: { items: 3 },
    1024: { items: 4 }
  };

  render() {
    // const handleOnDragStart = e => e.preventDefault();

    const items = this.props.employees.map(employee => (
      <div key={employee.id}>
        <EmployeeCard employee={employee} />
      </div>
    ));

    return (
      <div className="employee-card-carousel">
        <AliceCarousel
          disableAutoPlayOnAction={false}
          mouseDragEnabled={true}
          items={items}
          autoPlay={true}
          autoPlayInterval={5000}
          fadeOutAnimation={true}
          buttonsDisabled={true}
          responsive={this.responsive}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employees: state.employees
});

export default connect(mapStateToProps)(EmployeeCardCarousel);
