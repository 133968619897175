import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../store/actions/modalActions';
import { Modal, Icon, Message } from 'semantic-ui-react';
import Help from './Help';

class HelpModal extends Component {
  render() {
    return (
      <Modal size="small" open={true} onClose={this.props.closeModal} closeIcon>
        <Modal.Header>
          <Icon name="help circle" className="modal-header-icon" />A quick guide
          to our site
        </Modal.Header>
        <Modal.Content>
          <Help />
        </Modal.Content>
        <Message attached="bottom">
          <p>
            If you need any further information, please contact us at the
            pharmacy. We'll be happy to help you.
          </p>
        </Message>
      </Modal>
    );
  }
}

const mapDispatchToProps = { closeModal };

export default connect(
  null,
  mapDispatchToProps
)(HelpModal);
