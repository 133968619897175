import { serviceApi } from '../../data/dataApi';
import { serviceActionType } from './actionTypes';
import { asyncError, asyncFinish, asyncStart } from './asyncActions';
import { addItem, deleteItem, setItems, updateItem } from './actionHelpers';

export const addServiceAsync = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());

      const { title, headline, description, imageUrl } = data;

      if (!title || !headline || !description || !imageUrl) {
        throw new Error('Invalid input');
      }

      const service = { title, headline, description, imageUrl };
      const id = await serviceApi.createAsync(service);
      const newService = { id, ...service };
      dispatch(addItem(newService, serviceActionType.ADD));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.message));
      throw new Error('Could not add service');
    }
  };
};

export const deleteServiceAsync = id => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await serviceApi.removeAsync(id);

      dispatch(deleteItem(id, serviceActionType.DELETE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not delete service');
    }
  };
};

export const setServicesAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      const services = await serviceApi.getAllAsync();
      dispatch(setItems(services, serviceActionType.SET));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw error;
    }
  };
};

export const updateServiceAsync = (id, service) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await serviceApi.updateAsync(id, service);
      dispatch(updateItem(id, service, serviceActionType.UPDATE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not update service');
    }
  };
};
