import React from 'react';
import { Form, Label } from 'semantic-ui-react';

const TextArea = ({
  input,
  width,
  type,
  placeholder,
  rows = 5, // default rows
  meta: { touched, error }
}) => {
  return (
    <Form.Field error={touched && !!error} width={width}>
      <textarea {...input} placeholder={placeholder} type={type} rows={rows} />
      {touched && error && (
        <Label pointing basic color="green">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default TextArea;
