import React from 'react';
import { Segment, Header, Image } from 'semantic-ui-react';

const ServiceWideScreen = props => {
  const { service } = props;

  const title = service ? service.title : 'Our Services';
  const image = service ? service.imageUrl : '/assets/pharmacist.jpg';
  const imageAlt = service ? 'Service' : 'Pharmacist';
  const headline = service
    ? service.headline
    : "We've set out to provide a list of some of our services. We hope you find it useful.";
  const description = service
    ? service.description
    : "If you have any questions, please get in touch, we'll be happy to assist you. To view more details about our services, tap on its name in the menu on the right.";

  return (
    <Segment.Group className="service">
      <Segment className="service__header">
        <Header as="h1" className="service__title">
          {title}
        </Header>
      </Segment>

      <Segment className="service__image">
        <Image src={image} alt={imageAlt} />
      </Segment>

      <Segment className="service__main">
        {headline && <h3 className="service__headline">{headline}</h3>}
        <p className="service__description">{description}</p>
      </Segment>
    </Segment.Group>
  );
};

export default ServiceWideScreen;
