import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
// prettier-ignore
import { deleteEmployeeAsync,updateEmployeeAsync } from '../../../store/actions/employeeActions';
import EmployeeForm from './EmployeeForm';

const EditEmployee = props => {
  const { deleteEmployeeAsync, history, employee, updateEmployeeAsync } = props;
  const redirectUrl = '/admin/employees'; // change to reference a constant later

  const onUpdate = async employee => {
    try {
      await updateEmployeeAsync(employee.id, employee);
      history.push(redirectUrl);
    } catch (error) {
      const message = 'Edit employee error: ' + error.message;
      alert(message);
    }
  };

  const onRemove = async () => {
    try {
      await deleteEmployeeAsync(employee.id);
      history.push(redirectUrl);
    } catch (error) {
      const message = 'Remove employee error: ' + error.message;
      alert(message);
    }
  };

  return (
    <div className="edit-employee admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Edit Employee
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <EmployeeForm
          onSubmit={onUpdate}
          onRemove={onRemove}
          employee={employee}
        />
      </Segment>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  employee: state.employees.find(
    employee => employee.id === props.match.params.id
  )
});

const mapDispatchToProps = {
  deleteEmployeeAsync,
  updateEmployeeAsync
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmployee);
