import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { firebase } from './data/firebase/firebaseConfig';
// import { configureStore } from './store/configureStore';
import configureStore from './store/configureStore';
import { login, logout } from './store/actions/authActions';
import { setEmployeesAsync } from './store/actions/employeeActions';
import { setHoursAsync } from './store/actions/hoursActions';
import { setResourcesAsync } from './store/actions/resourceActions';
import { setServicesAsync } from './store/actions/serviceActions';
import App from './components/App';
import ScrollToTop from './components/helpers/ScrollToTop';
import 'semantic-ui-css/semantic.min.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import './styles/styles.css';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

let jsx = (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(jsx, document.getElementById('root'));

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    const currentUser = {
      uid: user.uid,
      displayName: user.displayName,
      isAdmin:
        user.uid === 'd86LUEIGDlT932V5ksZD8jQ4jup1' ||
        user.uid === 'Mo4lJgqZ2GQKfntJVD6TtbXkmSj2' ||
        user.uid === 'BYRERkfs53Qc8qoM1RSeJe9lFvH2'
    };

    // When registering, the display name isnt' set until a profile update is performed ... hence is incomplete until then
    if (!!currentUser.displayName) {
      store.dispatch(login(currentUser));
    }
  } else {
    store.dispatch(logout());
  }
});

const loadDataAsync = async () => {
  try {
    await store.dispatch(setEmployeesAsync());
    await store.dispatch(setHoursAsync());
    await store.dispatch(setResourcesAsync());
    await store.dispatch(setServicesAsync());
  } catch (error) {
    alert(
      'Apologies. There was a problem loading up our website. Please click the refresh button to retry'
    );
  }
};

loadDataAsync();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
