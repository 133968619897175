import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../store/actions/modalActions';
import { Modal, Icon, Message } from 'semantic-ui-react';
import { openModal } from '../../store/actions/modalActions';
import LoginPage from './LoginPage';

class LoginModal extends Component {
  handleModal = item => () => {
    this.props.openModal(item);
  };

  render() {
    const { user } = this.props;

    return (
      <Modal size="small" open={true} onClose={this.props.closeModal} closeIcon>
        <Modal.Header>
          <Icon name="user" className="modal-header-icon" />
          Sign-in for bonus features
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <LoginPage user={user} />
          </Modal.Description>
        </Modal.Content>
        <Message attached="bottom">
          Not registered yet?{' '}
          <span
            className="modal-message-cta"
            onClick={this.handleModal('RegisterModal')}
          >
            Sign-up here
          </span>
        </Message>
      </Modal>
    );
  }
}

const mapDispatchToProps = { closeModal, openModal };

export default connect(
  null,
  mapDispatchToProps
)(LoginModal);
