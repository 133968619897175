import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import ServiceSmallScreen from './ServiceSmallScreen';
import ServicesSmallScreenMenu from './ServicesSmallScreenMenu';

export class ServicesSmallScreen extends Component {
  state = {
    selectedIds: []
  };

  handleSelection = (e, { value }) => {
    this.setState({ selectedIds: value });
  };

  render() {
    const { selectedIds } = this.state;
    const { services } = this.props;

    const dropdownOptions = services.map((service, index) => {
      return {
        key: index,
        text: service.title,
        value: service.id
      };
    });

    const selectedServices =
      selectedIds.length > 0
        ? services.filter(service => selectedIds.includes(service.id))
        : services;

    return (
      <Grid padded className="services-smallscreen">
        <Grid.Column width={16} className="services-smallscreen__intro">
          <h3 className="services-smallscreen__intro__primary">
            We've set out to provide a list of some of our services. We hope you
            find it useful.
          </h3>
          <h4 className="services-smallscreen__intro__secondary">
            If you have any questions, please get in touch, we'll be happy to
            hear from you.
          </h4>
          <h4 className="services-smallscreen__intro__secondary">
            Simply scroll down to view all our services or use the drop down
            list to find the service(s) you're interested in.
          </h4>
        </Grid.Column>
        <Grid.Column width={16}>
          <ServicesSmallScreenMenu
            options={dropdownOptions}
            handleSelection={this.handleSelection}
          />
        </Grid.Column>
        <Grid.Column width={16} className="services-smallscreen__main">
          {selectedServices.map(service => (
            <div className="service-container" key={service.id}>
              <ServiceSmallScreen service={service} />
            </div>
          ))}
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  services: state.services
});

export default connect(mapStateToProps)(ServicesSmallScreen);
