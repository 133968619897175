import { resourceApi } from '../../data/dataApi';
import { resourceActionType } from './actionTypes';
import { asyncError, asyncFinish, asyncStart } from './asyncActions';
import { addItem, deleteItem, setItems, updateItem } from './actionHelpers';

export const addResourceAsync = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());

      const { title, url } = data;
      if (!(title && url)) {
        throw new Error('Invalid input');
      }

      const resource = { title, url };
      const id = await resourceApi.createAsync(resource);
      const newResource = { id, ...resource };
      dispatch(addItem(newResource, resourceActionType.ADD));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.message));
      throw new Error('Could not add resource');
    }
  };
};

export const deleteResourceAsync = id => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await resourceApi.removeAsync(id);
      dispatch(deleteItem(id, resourceActionType.DELETE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not delete resource');
    }
  };
};

export const setResourcesAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      const resources = await resourceApi.getAllAsync();
      dispatch(setItems(resources, resourceActionType.SET));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw error;
    }
  };
};

export const updateResourceAsync = (id, resource) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await resourceApi.updateAsync(id, resource);
      dispatch(updateItem(id, resource, resourceActionType.UPDATE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not update resource');
    }
  };
};
