import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { closeModal } from '../../store/actions/modalActions';
import { Modal, Icon, Button, Message } from 'semantic-ui-react';

class ResourceModal extends Component {
  handleRedirect = url => () => {
    this.props.history.push('/');
    window.location = url;
  };

  render() {
    const { resource } = this.props;

    return (
      <Modal size="small" open={true} onClose={this.props.closeModal} closeIcon>
        <Modal.Header>
          <Icon name="external" className="modal-header-icon" />
          You're about to be redirected to an external website
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <h2>{resource.title}</h2>
            <h4>{resource.url}</h4>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              onClick={this.handleRedirect(resource.url)}
              positive
              icon="checkmark"
              content="Continue"
            />
            <Button.Or />
            <Button
              icon="cancel"
              content="I've changed my mind"
              onClick={this.props.closeModal}
            />
          </Button.Group>
        </Modal.Actions>
        <Message attached="bottom">
          <p>
            To return to ours afterwards, please use the back button of your
            browser.
          </p>
        </Message>
      </Modal>
    );
  }
}

const mapDispatchToProps = { closeModal };

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ResourceModal)
);
