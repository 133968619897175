import { hoursActionType } from '../actions/actionTypes';
import { addItem, removeItem, updateItem } from './reducerHelpers';

const initialState = [];

const hoursReducer = (state = initialState, action) => {
  switch (action.type) {
    case hoursActionType.ADD: {
      return addItem(state, action.item);
    }

    case hoursActionType.DELETE: {
      return removeItem(state, action.id);
    }

    case hoursActionType.UPDATE: {
      return updateItem(state, action.id, action.item);
    }

    case hoursActionType.SET: {
      return action.items;
    }

    default:
      return state;
  }
};

export default hoursReducer;
