import React, { Component } from 'react';
import { Menu, Icon, Grid } from 'semantic-ui-react';
import AdminRouter from '../../routers/AdminRouter';
import { withRouter } from 'react-router-dom';

export class AdminWideScreen extends Component {
  state = {};

  handleItemClick = (e, { name }) => {
    const activeItem = name;
    this.setState({ activeItem });
    this.props.history.push(`/admin/${name}`);
  };

  render() {
    const { pathname } = this.props.location;

    return (
      <Grid className="admin-widescreen">
        <Grid.Column width={6}>
          <Menu borderless vertical className="admin-widescreen__menu">
            <Menu.Item
              className="admin-widescreen__menu__item"
              name="resources"
              active={pathname === '/admin/resources'}
              onClick={this.handleItemClick}
            >
              <Icon name="external" />
            </Menu.Item>
            <Menu.Item
              className="admin-widescreen__menu__item"
              name="services"
              active={pathname === '/admin/services'}
              onClick={this.handleItemClick}
            >
              <Icon name="medkit" />
            </Menu.Item>
            <Menu.Item
              className="admin-widescreen__menu__item"
              name="employees"
              active={pathname === '/admin/employees'}
              onClick={this.handleItemClick}
            >
              <Icon name="users" />
            </Menu.Item>
            <Menu.Item
              className="admin-widescreen__menu__item"
              name="hours"
              active={pathname === '/admin/hours'}
              onClick={this.handleItemClick}
            >
              <Icon name="clock outline" />
            </Menu.Item>
          </Menu>
        </Grid.Column>

        <Grid.Column width={10}>
          <div className="admin-widescreen__main">
            <AdminRouter />
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(AdminWideScreen);
