import React, { Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import AdminSmallScreen from './AdminSmallScreen';
import AdminWideScreen from './AdminWideScreen';

const AdminPage = () => {
  return (
    <Fragment>
      <Responsive as={Fragment} maxWidth={991}>
        <AdminSmallScreen />
      </Responsive>
      <Responsive as={Fragment} minWidth={992}>
        <AdminWideScreen />
      </Responsive>
    </Fragment>
  );
};

export default AdminPage;
