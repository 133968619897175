import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import ResourceList from './ResourceList';

const ResourceAdmin = () => {
  return (
    <div className="resource-admin admin-section">
      <Segment className="admin-section__header" inverted color="green" padded>
        <Header as="h2" className="admin-section__title">
          Resource Admin
        </Header>
      </Segment>
      <ResourceList />
    </div>
  );
};

export default ResourceAdmin;
