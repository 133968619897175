import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import AddEmployee from '../components/employees/admin/AddEmployee';
import EditEmployee from '../components/employees/admin/EditEmployee';
import EmployeeAdmin from '../components/employees/admin/EmployeeAdmin';

const EmployeeAdminRouter = () => {
  return (
    <Switch>
      <AdminRoute exact path="/admin/employees/add" component={AddEmployee} />
      <AdminRoute
        exact
        path="/admin/employees/edit/:id"
        component={EditEmployee}
      />
      <AdminRoute exact path="/admin/employees" component={EmployeeAdmin} />
      <Redirect to="/admin/employees" />
    </Switch>
  );
};

export default EmployeeAdminRouter;
