import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import About from '../components/about/About';
import Admin from '../components/admin/Admin';
import Home from '../components/home/Home';
import Resources from '../components/resources/Resources';
import Services from '../components/services/Services';
import AdminRoute from './AdminRoute';

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/resources" component={Resources} />
      <Route exact path="/services" component={Services} />
      <AdminRoute path="/admin" component={Admin} />
      <Redirect to="/" />
    </Switch>
  );
};

export default AppRouter;
