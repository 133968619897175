import { employeeApi } from '../../data/dataApi';
import { employeeActionType } from './actionTypes';
import { asyncError, asyncFinish, asyncStart } from './asyncActions';
import { addItem, deleteItem, setItems, updateItem } from './actionHelpers';

export const addEmployeeAsync = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());

      const { name, role, bio, imageUrl } = data;

      if (!name || !role || !bio || !imageUrl) {
        throw new Error('Invalid input');
      }

      const employee = { name, role, bio, imageUrl };
      const id = await employeeApi.createAsync(employee);
      const newEmployee = { id, ...employee };
      dispatch(addItem(newEmployee, employeeActionType.ADD));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.message));
      throw new Error('Could not add employee');
    }
  };
};

export const deleteEmployeeAsync = id => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await employeeApi.removeAsync(id);

      dispatch(deleteItem(id, employeeActionType.DELETE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not delete employee');
    }
  };
};

export const setEmployeesAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      const employees = await employeeApi.getAllAsync();
      dispatch(setItems(employees, employeeActionType.SET));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw error;
    }
  };
};

export const updateEmployeeAsync = (id, employee) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncStart());
      await employeeApi.updateAsync(id, employee);
      dispatch(updateItem(id, employee, employeeActionType.UPDATE));
      dispatch(asyncFinish());
    } catch (error) {
      dispatch(asyncError(error.code));
      throw new Error('Could not update employee');
    }
  };
};
