import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const ServicesSmallScreenMenu = props => {
  const { options } = props;

  return (
    <Dropdown
      className="services-smallscreen-menu"
      // search
      loading={!options || options.length === 0}
      multiple
      placeholder="Select service(s) (optional)"
      fluid
      clearable
      options={options}
      selection
      onChange={props.handleSelection}
    />
  );
};

export default ServicesSmallScreenMenu;
