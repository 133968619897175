import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
import { addHoursAsync } from '../../../store/actions/hoursActions';
import HoursForm from './HoursForm';

const AddHours = props => {
  const { addHoursAsync, history } = props;
  const redirectUrl = '/admin/hours'; // change to reference a constant later

  const onAdd = async hours => {
    try {
      await addHoursAsync(hours);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem adding hours');
    }
  };

  return (
    <div className="add-resource admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Add Hours
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <HoursForm onSubmit={onAdd} />
      </Segment>
    </div>
  );
};

const mapDispatchToProps = { addHoursAsync };

export default connect(
  null,
  mapDispatchToProps
)(AddHours);
