import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
// prettier-ignore
import { deleteHoursAsync,updateHoursAsync } from '../../../store/actions/hoursActions';
import HoursForm from './HoursForm';

const EditHours = props => {
  const { deleteHoursAsync, history, hours, updateHoursAsync } = props;
  const redirectUrl = '/admin/hours'; // change to reference a constant later

  const onUpdate = async hours => {
    try {
      await updateHoursAsync(hours.id, hours);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem updating hours');
    }
  };

  const onRemove = async () => {
    try {
      await deleteHoursAsync(hours.id);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem removing hours');
    }
  };

  return (
    <div className="edit-hours admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Edit Hours
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <HoursForm onSubmit={onUpdate} onRemove={onRemove} hours={hours} />
      </Segment>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  hours: state.hours.find(hours => hours.id === props.match.params.id)
});

const mapDispatchToProps = {
  deleteHoursAsync,
  updateHoursAsync
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditHours);
