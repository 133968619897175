import React, { Component } from 'react';
import FilePickerModal from './FilePickerModal';
import ImageCropperModal from './ImageCropperModal';

const initialState = { cropperOpen: false, imageUrl: '' };

export class ImagePicker extends Component {
  state = initialState;

  resetState = () => this.setState(initialState);

  onClose = () => {
    this.resetState();
    this.props.closePicker();
  };

  onSelected = (file, metadata) => {
    const imageUrl = URL.createObjectURL(file);

    if (!this.props.aspectRatio) {
      this.props.handleImage(file);
      this.props.closePicker();
    } else {
      this.setState({ imageUrl, cropperOpen: true });
    }
  };

  handleCrop = imageUrl => {
    this.resetState();
    this.props.handleImage(imageUrl);
    this.onClose();
  };

  render() {
    const { cropperOpen, imageUrl } = this.state;
    const { pickerOpen, aspectRatio } = this.props;

    return (
      <React.Fragment>
        <FilePickerModal
          closePicker={this.onClose}
          handleSelection={this.onSelected}
          isOpen={pickerOpen}
        />

        <ImageCropperModal
          aspectRatio={aspectRatio}
          handleClose={this.onClose}
          handleCrop={this.handleCrop}
          isOpen={cropperOpen}
          imageUrl={imageUrl}
        />
      </React.Fragment>
    );
  }
}

export default ImagePicker;
