export const asyncActionType = {
  START: 'ASYNC_START',
  FINISH: 'ASYNC_FINISH',
  ERROR: 'ASYNC_ERROR'
};

export const authActionType = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
};

export const employeeActionType = {
  ADD: 'ADD_EMPLOYEE',
  DELETE: 'DELETE_EMPLOYEE',
  SET: 'SET_EMPLOYEES',
  UPDATE: 'UPDATE_EMPLOYEE'
};

export const hoursActionType = {
  ADD: 'ADD_HOURS',
  DELETE: 'DELETE_HOURS',
  SET: 'SET_HOURSS',
  UPDATE: 'UPDATE_HOURS'
};

export const crudActionType = {
  ADD: 'ADD_SAMPLE',
  DELETE: 'DELETE_SAMPLE',
  SET: 'SET_SAMPLES',
  UPDATE: 'UPDATE_SAMPLE'
};

export const modalActionType = {
  CLOSE: 'MODAL_CLOSE',
  OPEN: 'MODAL_OPEN'
};

export const notificationActionType = {
  SET: 'SET_NOTIFICATION',
  CLEAR: 'CLEAR_NOTIFICATION'
};

export const resourceActionType = {
  ADD: 'ADD_RESOURCE',
  DELETE: 'DELETE_RESOURCE',
  SET: 'SET_RESOURCES',
  UPDATE: 'UPDATE_RESOURCE'
};

export const serviceActionType = {
  ADD: 'ADD_SERVICE',
  DELETE: 'DELETE_SERVICE',
  SET: 'SET_SERVICES',
  UPDATE: 'UPDATE_SERVICE'
};

export const setupActionType = {
  START: 'START_SETUP',
  FINISH: 'FINISH_SETUP'
};

export const workdayActionType = {
  ADD: 'ADD_WORKDAY',
  DELETE: 'DELETE_WORKDAY',
  SET: 'SET_WORKDAYS',
  UPDATE: 'UPDATE_WORKDAY'
};
