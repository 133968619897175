import { database } from './firebaseConfig';

class FirebaseDataApi {
  constructor(refName) {
    this.refName = refName;
  }

  createAsync = async item => {
    try {
      const newItem = await database.ref(this.refName).push(item);
      return newItem.key;
    } catch (error) {
      throw error;
    }
  };

  getAllAsync = async () => {
    const items = [];

    try {
      const itemsSnapshot = await database.ref(this.refName).once('value');

      itemsSnapshot.forEach(item => {
        items.push({
          id: item.key,
          ...item.val()
        });
      });

      return items;
    } catch (error) {
      throw error;
    }
  };

  removeAsync = async id => {
    try {
      await database.ref(`${this.refName}/${id}`).remove();
    } catch (error) {
      throw error;
    }
  };

  updateAsync = async (id, item) => {
    try {
      item.id && delete item.id; // Remove 'id' key pair if it exists
      await database.ref(`${this.refName}/${id}`).update(item);
    } catch (error) {
      throw error;
    }
  };
}

export default FirebaseDataApi;
