import React, { Component } from 'react';
import { Menu, Grid, Header } from 'semantic-ui-react';

export class ServicesWideScreenMenu extends Component {
  render() {
    const { handleSelection, selectedService, services } = this.props;

    return (
      <Grid.Column width={16}>
        <Menu vertical fluid className="services-widescreen-menu">
          <Header
            className="services-widescreen-menu__header"
            attached
            inverted
          >
            <h1 className="services-widescreen-menu__title">Links</h1>
          </Header>
          {services &&
            services.map(service => (
              <Menu.Item
                onClick={handleSelection(service)}
                active={selectedService && service.id === selectedService.id}
                className="services-widescreen-menu__item"
                key={service.id}
              >
                {service.title}
              </Menu.Item>
            ))}
        </Menu>
      </Grid.Column>
    );
  }
}

export default ServicesWideScreenMenu;
