import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, List, Segment } from 'semantic-ui-react';
import ResourceListItem from './ResourceListItem';

const ResourceList = props => {
  const { resources, history } = props;

  const handleAdd = () => {
    history.push('/admin/resources/add');
  };

  return (
    <div className="resource-list admin-list">
      <Button
        className="admin-button__add"
        onClick={handleAdd}
        content="Add"
        icon="add"
        size="tiny"
      />

      <Segment>
        <List className="resource-list__items">
          {resources.map(resource => (
            <ResourceListItem
              key={resource.id}
              resource={resource}
              history={history}
            />
          ))}
        </List>
      </Segment>
    </div>
  );
};

const mapStateToProps = state => ({
  resources: state.resources
});

export default withRouter(connect(mapStateToProps)(ResourceList));
