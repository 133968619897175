import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../store/actions/modalActions';
import { Modal, Icon, Message } from 'semantic-ui-react';
import RegisterPage from './RegisterPage';

class RegisterModal extends Component {
  handleModal = item => () => {
    this.props.openModal(item);
  };

  render() {
    return (
      <Modal size="small" open={true} onClose={this.props.closeModal} closeIcon>
        <Modal.Header>
          <Icon name="user plus" />
          Sign-up for bonus features
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <RegisterPage />
          </Modal.Description>
        </Modal.Content>
        <Message attached="bottom">
          Already registered?{' '}
          <span
            className="modal-message-cta"
            onClick={this.handleModal('LoginModal')}
          >
            Sign-in here
          </span>
        </Message>
      </Modal>
    );
  }
}

const mapDispatchToProps = { closeModal, openModal };

export default connect(
  null,
  mapDispatchToProps
)(RegisterModal);
