import React, { Component } from 'react';
import { Item, Header, Icon, Segment } from 'semantic-ui-react';

export class ResourceCard extends Component {
  render() {
    const { resource } = this.props;

    return (
      <Segment.Group className="resource-card">
        <Segment className="resource-card__header" inverted>
          <Header as="h5" className="resource-card__title">
            {resource.title}
          </Header>
        </Segment>
        <Segment className="resource-card__item">
          <Item>
            <Item.Content className="resource-card__content">
              <span className="resource-url">{resource.url}</span>
              <a href={resource.url} className="resource-link">
                <Icon name="external" size="large" />
              </a>
            </Item.Content>
          </Item>
        </Segment>
      </Segment.Group>
    );
  }
}

export default ResourceCard;
