import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
// prettier-ignore
import { deleteResourceAsync,updateResourceAsync } from '../../../store/actions/resourceActions';
import ResourceForm from './ResourceForm';

const EditResource = props => {
  const { deleteResourceAsync, history, resource, updateResourceAsync } = props;
  const redirectUrl = '/admin/resources'; // change to reference a constant later

  const onUpdate = async resource => {
    try {
      await updateResourceAsync(resource.id, resource);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem updating resource');
    }
  };

  const onRemove = async () => {
    try {
      await deleteResourceAsync(resource.id);
      history.push(redirectUrl);
    } catch (error) {
      alert('Problem removing resource');
    }
  };

  return (
    <div className="edit-resource admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Edit Resource
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <ResourceForm
          onSubmit={onUpdate}
          onRemove={onRemove}
          resource={resource}
        />
      </Segment>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  resource: state.resources.find(
    resource => resource.id === props.match.params.id
  )
});

const mapDispatchToProps = {
  deleteResourceAsync,
  updateResourceAsync
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditResource);
