import { resourceActionType } from '../actions/actionTypes';
import { addItem, removeItem, updateItem } from './reducerHelpers';

const initialState = [];

const resourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case resourceActionType.ADD: {
      return addItem(state, action.item);
    }

    case resourceActionType.DELETE: {
      return removeItem(state, action.id);
    }

    case resourceActionType.UPDATE: {
      return updateItem(state, action.id, action.item);
    }

    case resourceActionType.SET: {
      return action.items;
    }

    default:
      return state;
  }
};

export default resourceReducer;
