import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { logoutAsync } from '../../store/actions/authActions';
import { openModal } from '../../store/actions/modalActions';
import { Button } from 'semantic-ui-react';

const SideDrawerMenu = props => {
  const { auth, history, closeSideDrawer, logoutAsync, openModal } = props;
  const isAuthenticated = !!auth.user;
  const isAdmin = isAuthenticated && auth.user.isAdmin;

  const handleModal = item => () => {
    openModal(item);
  };

  const handleSelection = item => () => {
    history.push(item);
    closeSideDrawer();
  };

  const handleSignout = () => {
    logoutAsync();
  };

  return (
    <div className="side-drawer-menu">
      <Button.Group
        // vertical
        labeled
        icon
        className="side-drawer-menu-main side-drawer-menu-section"
      >
        <Button
          icon="info"
          content="About Us"
          onClick={handleSelection('/about')}
        />
        <Button
          icon="medkit"
          content="Services"
          onClick={handleSelection('/services')}
        />
        <Button
          icon="external"
          content="Resources"
          onClick={handleSelection('/resources')}
        />
      </Button.Group>

      <Button.Group
        // vertical
        labeled
        icon
        className="side-drawer-menu-user side-drawer-menu-section"
      >
        {isAuthenticated && (
          <Button
            className="user-menu-option"
            icon="power off"
            content="Sign-out"
            onClick={handleSignout}
          />
        )}
        {!isAuthenticated && (
          <Button
            className="user-menu-option"
            icon="user"
            content="Sign-in/Register"
            onClick={handleModal('LoginModal')}
          />
        )}

        {isAdmin && (
          <Fragment>
            <Button
              className="user-menu-option"
              icon="settings"
              content="Manage Content"
              onClick={handleSelection('/admin')}
            />
          </Fragment>
        )}
      </Button.Group>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = { openModal, logoutAsync };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideDrawerMenu);
