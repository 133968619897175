export const addItem = (item, actionType) => {
  const action = {
    type: actionType,
    item
  };

  return action;
};

export const deleteItem = (id, actionType) => {
  const action = {
    type: actionType,
    id
  };

  return action;
};

export const setItems = (items, actionType) => {
  const action = {
    type: actionType,
    items
  };

  return action;
};

export const updateItem = (id, item, actionType) => {
  const action = {
    type: actionType,
    id,
    item
  };

  return action;
};
