import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyD9HuTs8WIfdmLLQZZEUvGc1i8lww8nHjs',
  authDomain: 'east-lindfield-pharmacy.firebaseapp.com',
  databaseURL: 'https://east-lindfield-pharmacy.firebaseio.com',
  projectId: 'east-lindfield-pharmacy',
  storageBucket: 'east-lindfield-pharmacy.appspot.com',
  messagingSenderId: '523836700091'
};

firebase.initializeApp(config);

export { firebase };
export const database = firebase.database();

// const config = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DATABASE_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
// };

// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
