import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, List, Segment } from 'semantic-ui-react';
import HoursListItem from './HoursListItem';

const HoursList = props => {
  const { hours, history } = props;

  const handleAdd = () => {
    history.push('/admin/hours/add');
  };

  return (
    <div className="hours-list admin-list">
      <Button
        className="admin-button__add"
        onClick={handleAdd}
        content="Add"
        icon="add"
        size="tiny"
      />

      <Segment>
        <List className="hours-list__items">
          {hours.map(hours => (
            <HoursListItem key={hours.id} hours={hours} history={history} />
          ))}
        </List>
      </Segment>
    </div>
  );
};

const mapStateToProps = state => ({
  hours: state.hours
});

export default withRouter(connect(mapStateToProps)(HoursList));
