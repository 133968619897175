import React from 'react';
import { connect } from 'react-redux';
import ResourceCard from './ResourceCard';

const ResourceCards = props => {
  const { resources } = props;

  return (
    <div className="resource-cards">
      {resources.map(resource => (
        <ResourceCard key={resource.id} resource={resource} />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  resources: state.resources
});

export default connect(mapStateToProps)(ResourceCards);
