import { authActionType } from '../actions/actionTypes';

const initialState = {};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionType.LOGIN: {
      return { user: action.user };
    }

    case authActionType.LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default authReducer;
