import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { closeModal } from '../../store/actions/modalActions';
import { loginAsync } from '../../store/actions/authActions';
import { Divider, Header, Icon } from 'semantic-ui-react';
import LoginForm from './LoginForm';

export class LoginPage extends Component {
  state = {
    error: ''
  };

  handleLogin = async user => {
    try {
      this.setState({ error: '' });
      await this.props.loginAsync(user);
      this.props.closeModal();
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  render() {
    const { error } = this.state;
    const { user } = this.props;

    return (
      <div className="register-page">
        {user && !error && (
          <React.Fragment>
            <Header as="h5">
              <Icon name="info" color="red" />
              <Header.Content>
                Thanks for registering. You've been sent an email to verify your
                identity. Simply click on the verification link in that email
                and then use this form to login. If you cannot find the email in
                your inbox, please check your spam/junk email folders.
              </Header.Content>
            </Header>
            <Divider />
          </React.Fragment>
        )}
        {error && (
          <React.Fragment>
            <Header as="h4">
              <Icon name="info" color="red" />
              <Header.Content>{error}</Header.Content>
            </Header>
            <Divider />
          </React.Fragment>
        )}
        <LoginForm onLogin={this.handleLogin} />
      </div>
    );
  }
}

const mapDispatchToProps = { closeModal, loginAsync };

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(LoginPage)
);
