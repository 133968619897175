import React from 'react';
import { connect } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';
import { addEmployeeAsync } from '../../../store/actions/employeeActions';
import EmployeeForm from './EmployeeForm';

const AddEmployee = props => {
  const { addEmployeeAsync, history } = props;
  const redirectUrl = '/admin/employees'; // change to reference a constant later

  const onAdd = async employee => {
    try {
      await addEmployeeAsync(employee);
      history.push(redirectUrl);
    } catch (error) {
      const message = 'Add employee error: ' + error.message;
      alert(message);
    }
  };

  return (
    <div className="add-employee admin-page">
      <Segment className="admin-page__header" inverted color="green" padded>
        <Header as="h2" className="admin-page____title">
          Add Employee
        </Header>
      </Segment>

      <Segment className="admin-page__main">
        <EmployeeForm onSubmit={onAdd} />
      </Segment>
    </div>
  );
};

const mapDispatchToProps = { addEmployeeAsync };

export default connect(
  null,
  mapDispatchToProps
)(AddEmployee);
