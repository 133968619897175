import { firebase } from './firebaseConfig';
import { userErrorTypes } from '../../helpers/errorTypes';
import ValidationError from '../../helpers/ValidationError';

class FirebaseAuthenticationApi {
  registerUserAsync = async user => {
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);

      // Probably change to const later
      var currentUser = firebase.auth().currentUser;

      await currentUser.updateProfile({
        displayName: user.displayName
      });

      await currentUser.sendEmailVerification(); // Maybe check for email verified on isAuthenticated ... then this should change when user click email
      await firebase.auth().signOut(); // Maybe remove this line if above is done
    } catch (error) {
      throw error;
    }
  };

  loginUserAsync = async user => {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);

      const firebaseUser = await firebase.auth().currentUser;
      const isVerified = firebaseUser.emailVerified;

      if (!isVerified) {
        await firebase.auth().signOut();
        throw new ValidationError('Please verify your email first');
      }

      const isAdmin = firebaseUser.displayName.toLowerCase() === 'neil';

      // Change isAdmin setting later
      const currentUser = {
        uid: firebaseUser.uid,
        displayName: firebaseUser.displayName,
        isAdmin: isAdmin
      };

      return currentUser;
    } catch (error) {
      if (error.code === userErrorTypes.USER_NOT_FOUND) {
        throw new ValidationError(
          'Please check your email address and password. We do not have a registered user with the credentials you provided'
        );
      }

      throw error;
    }
  };

  logoutUserAsync = async () => {
    try {
      return await firebase.auth().signOut();
    } catch (error) {
      throw error;
    }
  };
}

export default FirebaseAuthenticationApi;
