import React from 'react';
import { Icon } from 'semantic-ui-react';

const SideDrawerInstructions = () => {
  return (
    <div className="side-drawer-instructions">
      Tap an option above or{' '}
      <Icon name="close" className="side-drawer-instructions__icon" />
      to close
    </div>
  );
};

export default SideDrawerInstructions;
